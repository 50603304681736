// Step 1: Import React
import * as React from 'react'
import LayoutHome from '../components/layout-home'
let homeFace = require('../images/home-face.png')


// Step 2: Define your component
const IndexPage = () => {
  return (
    <LayoutHome pageTitle="Home Page">
      <div className="main-content home-page col-lg-9 col-12">
      <h1>Hey there, I'm Daniel Richtmyre.</h1>
      <div className="sub-title row">
        <div className="main-image col-md-7"><img src={homeFace.default} alt="An outline drawing of Daniel Richtmyre's head, a man with stubble hair, thick rimmed glasses, and a dimple chin." /></div>
        <div className="main-language col-md-5">
          <h2>I'm a front end web developer that likes to use creativity, imagination, and a bit of fancy code to build engaging online experiences and make a difference in the world.</h2>

          <h2><a href="/contact">What can I help you with?</a></h2>
        </div>
      </div>
      </div>
    </LayoutHome>
  )
}
// Step 3: Export your component
export default IndexPage