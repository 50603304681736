import * as React from 'react'
import Navigation from './nav'
import { Helmet } from 'react-helmet'
import favicon from '../images/favicon.ico'


const LayoutHome = ({ pageTitle, children }) => {
  return (
    <main className="container-md p-0">
      <Helmet>
        <title>Daniel Richtmyre's Website</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div className="row gx-0">
        <Navigation />
        {children}
      </div>
    </main>
  )
}

export default LayoutHome